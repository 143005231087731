import React from 'react';
import { StaticImage, GatsbyImage } from "gatsby-plugin-image"
import HeroBlock from '../components/HeroBlock';
import CallToAction from '../components/CallToAction';
import LatestPosts from '../components/LatestPosts';
import Layout from '../components/Layout';
import { StyledTimeline, TimelineItem, TimelineContent } from '../components/Timeline';
import Seo from '../components/Seo'
import DirectusForm from '../components/DirectusForm';

export default function Home() {

  return (

    <Layout>
      <Seo 
        lang="en"
        description="Executive AI skills delivered by AI natives"
        title="Your Personal Trainer for Executive AI skills"
      />

      <HeroBlock 
        bgcolor="light" 
        image={<StaticImage src="../images/pexels-george-milton-6953835.jpg" alt="" />}
        content={(
          <>
            <h1>Hetki.io is your Personal Trainer for Executive AI skills</h1>
            <CallToAction bgcolor="brandSecondary" url="https://www.hetki.io/" align="left">Visit hetki.io</CallToAction> 
          
          </>
        )} />  


      {/*  <LatestPosts maxNumberOfPosts="16" title="Latest posts"/>    */} 

    </Layout>

  )

}
